import {useGetJobQuery} from 'components/customer/Job/store/jobApi';
import {useEffect, useState} from 'react';
import {urlPartsJob} from 'shared/helpers';
import {PartialRoom} from 'shared/types/PartialRoom';

export const useFetchSidebarRooms = () => {
    const [jobId] = urlPartsJob();
    const [rooms, setRooms] = useState<PartialRoom[]>([]);

    const {
        data: job,
        isLoading,
        isFetching,
        isError,
    } = useGetJobQuery(
        {jobId: Number(jobId) - 10000},
        {
            skip: typeof jobId === 'undefined',
        }
    );

    useEffect(() => {
        if (job && job.rooms) {
            setRooms(job.rooms);
        }
    }, [job]);

    return {
        rooms,
        isLoading,
        isFetching,
        isError,
    };
};
