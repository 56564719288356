import {HardwareOption} from 'components/customer/Product/entity';
import React from 'react';
import {Row} from 'react-bootstrap';
import {isDeviceSmall, isDeviceMid} from 'shared/helpers/DeviceSize';
import {Loader} from 'shared/helpers';
import styled from 'styled-components';
import {RadioCard} from 'shared/components/Forms/RadioCard';

export const DynamicRadioMobile = ({
    name,
    value,
    disabled = false,
    isLoading = false,
    yesOption,
    noOption,
    handleYesCheck,
    handleNoCheck,
    handleChange,
}: {
    name: string;
    value: boolean;
    disabled: boolean;
    isLoading: boolean;
    yesOption: HardwareOption;
    noOption: HardwareOption;
    handleYesCheck: () => void;
    handleNoCheck: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
    const isSmallDevice = isDeviceSmall();
    const deviceIsMedium = isDeviceMid(false);

    return (
        <>
            <Loader loader={isLoading} />
            <RowWrapper $hasPadding={isSmallDevice || deviceIsMedium}>
                {!yesOption.hasOwnProperty('hidden') || !yesOption.hidden ? (
                    <RadioCard
                        type="yes"
                        isSelected={value}
                        handleCheck={handleYesCheck}
                        handleRadioChange={handleChange}
                        disabled={disabled}
                        fieldName={name}
                        option={yesOption}
                        isSmallDevice={isSmallDevice}
                    />
                ) : null}
                {!noOption.hasOwnProperty('hidden') || !noOption.hidden ? (
                    <RadioCard
                        type="no"
                        isSelected={!value}
                        handleCheck={handleNoCheck}
                        handleRadioChange={handleChange}
                        disabled={disabled}
                        fieldName={name}
                        option={noOption}
                        isSmallDevice={isSmallDevice}
                    />
                ) : null}
            </RowWrapper>
        </>
    );
};

const RowWrapper = styled(Row)<{
    $hasPadding?: boolean;
}>`
    ${({$hasPadding}) => ($hasPadding ? 'padding: 10px 10px 10px 5px' : '')};
`;
