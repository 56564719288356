import React, {useMemo, useState, useCallback} from 'react';
import {BaseConfig} from 'config';
import {useAppContext} from 'contexts';
import {JobMenuItem, SidebarToggle} from 'shared';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {SIDEBAR} from 'hooks';
import useUI from 'shared/useUI';
import {SidebarStatus, SidebarBehaviour} from 'store/customer/UISlice';
import Sidebar, {useLeftSidebar} from 'shared/components/Sidebar';
import MenuButton from 'shared/components/MenuButton';

export const SidebarLeft = () => {
    const isSmallDevice = isDeviceSmall();
    const {userLevel} = useAppContext();
    const {
        isSmallScreenLayout,
        leftSidebar,
        leftSidebarBehaviour,
        setLeftSidebar,
        showSidebarOverlays,
    } = useUI();
    const {toggle} = useLeftSidebar();
    const [hasVerticalScroll, setHasVerticalScroll] = useState(false);

    const jobMenu = useMemo(() => {
        let jobMenuItems = [] as Record<string, unknown>[];

        if (
            userLevel === 'USER_CUSTOMER' &&
            typeof BaseConfig[userLevel] !== 'undefined' // eslint-disable-line security/detect-object-injection
        ) {
            jobMenuItems = BaseConfig[userLevel]['jobMenu']; // eslint-disable-line security/detect-object-injection
        }

        return (
            <JobMenuItem
                jobMenu={jobMenuItems}
                isCollapsed={
                    leftSidebar != SidebarStatus.OPEN &&
                    leftSidebarBehaviour === SidebarBehaviour.TOGGLE
                }
            />
        );
    }, [leftSidebar, leftSidebarBehaviour, userLevel]);

    const handleNavRefChange = useCallback((ref: HTMLElement) => {
        const navRef = ref;
        if (navRef) {
            const hasVertScroll = navRef.scrollHeight > navRef.clientHeight;

            setHasVerticalScroll(hasVertScroll);
        }
    }, []);

    if (!isSmallDevice) {
        return (
            <Sidebar
                sidebarId="sidebar-left"
                className="sidebar-left"
                anchor="LEFT"
                behaviour={leftSidebarBehaviour}
                status={leftSidebar === SidebarStatus.OPEN ? 'OPEN' : 'CLOSED'}
                showOverlays={showSidebarOverlays}
                partialClose={!isSmallScreenLayout}
                partialCloseWidth={80}>
                <div className="sidebar-left-content">
                    <MenuButton status={leftSidebar} onClick={toggle} />
                    <section
                        // changing this to handleNavRefChange only is behaving incorrectly so disabling it for now
                        // eslint-disable-next-line react/jsx-no-bind
                        ref={(ref) => handleNavRefChange(ref)}
                        className="nav-container"
                        style={
                            isSmallScreenLayout &&
                            leftSidebar === SidebarStatus.CLOSED &&
                            hasVerticalScroll
                                ? {
                                      overflowY: 'hidden',
                                      paddingRight: 10,
                                  }
                                : {}
                        }>
                        {jobMenu}
                    </section>

                    {isSmallScreenLayout ? (
                        <SidebarToggle
                            className="left-sidebar-toggle"
                            direction={
                                leftSidebar === SidebarStatus.OPEN
                                    ? SIDEBAR.LEFT
                                    : SIDEBAR.RIGHT
                            }
                            status={leftSidebar}
                            setStatus={setLeftSidebar}
                            title="Toggle menu visibility"
                            behaviour={leftSidebarBehaviour}
                        />
                    ) : null}
                </div>
            </Sidebar>
        );
    } else {
        return null;
    }
};
