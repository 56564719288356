import React, {useState, useMemo, useRef, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import {ConfirmationDialog} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {usePrompt} from 'shared';

type NavigatorConfirmationDialogType = {
    showConfirmation: boolean;
    onClick: () => void;
    title?: string;
    message?: string;
};

type LocationType = {
    location: {pathname: string};
    showConfirmation: boolean;
    onClick: () => void;
};

export const NavigatorConfirmationDialog = ({
    showConfirmation,
    onClick,
    ...props
}: NavigatorConfirmationDialogType) => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const confirmed = useRef<boolean>(false);
    const location = useRef<LocationType>();

    // Using this because of limitation with v6.
    // TODO: use proper method in future if Prompt or similar feature is implemented
    // in v6. For time being this is the best way to achieve this functionality
    const retryNavigation = useRef<() => void>();

    usePrompt((data: LocationType, retry: () => void) => {
        retryNavigation.current = retry;
        if (!confirmed.current && showConfirmation) {
            setShowModal(true);

            location.current = data;
        }
    }, showConfirmation);

    const hideModal = useCallback(() => {
        setShowModal(false);
    }, []);

    const options = useMemo(
        () => ({
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Proceed',
                    show: true,
                    variant: 'danger',
                    action: () => {
                        confirmed.current = true;
                        onClick && onClick();

                        if (
                            typeof location.current.location.pathname ===
                            'string'
                        ) {
                            let state = {};
                            if ('state' in location.current.location) {
                                state = {
                                    state: location.current.location.state,
                                };
                            }

                            navigate(location.current.location.pathname, state);
                            setShowModal(false);
                            confirmed.current = false;
                        }
                        retryNavigation.current();
                    },
                },
                {
                    name: 'Cancel',
                    show: true,
                },
            ],
        }),
        [onClick]
    );

    return (
        <ConfirmationDialog
            {...props}
            show={showModal}
            options={options}
            hideDialog={hideModal}
            backdrop="static"
        />
    );
};
