import React, {useEffect, useState} from 'react';
import {Alert, Col, Form, Row} from 'react-bootstrap';
import {Icon, Loader} from 'shared/helpers';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import {useAppliance} from 'hooks';
import {FIELDSET_LAYOUT, GenericFields} from 'components/customer/Product';
import {useFormikContext} from 'formik';
import {useProductContext} from 'contexts';
import {MoreInfo, OverlayTrigger} from 'shared';

const APPLIANCE_TYPES = {
    oven: 0,
    rangehood: 1,
    microwave: 2,
};

export const getApplianceType = (type) => {
    const APPLIANCES = ['Oven', 'Rangehood', 'Microwave'];

    return APPLIANCES[type];
};

export const NewApplianceForm = ({newAppliance, messages}) => {
    const newForm = [
        {
            name: 'make',
            displayName: 'Make',
            type: 'text',
            value: '',
            options: {
                maximum: 150,
                mandatory: true,
            },
        },
        {
            name: 'model',
            displayName: 'Model',
            type: 'text',
            value: '',
            options: {
                maximum: 150,
                mandatory: true,
            },
        },
        {
            name: 'height',
            displayName: 'Height',
            type: 'size',
            value: 0,
        },
        {
            name: 'width',
            displayName: 'Width',
            type: 'size',
            value: 0,
        },
        {
            name: 'depth',
            displayName: 'Depth',
            type: 'size',
            value: 0,
        },
    ];

    return (
        <section className="cbc-form" style={{padding: 0, fontSize: 'initial'}}>
            {messages.length ? (
                <Alert
                    variant="danger"
                    style={{marginTop: 0, marginBottom: '15px'}}>
                    <Icon iconName="Button-Error.svg" />

                    {messages.map((message, index) => {
                        return <div key={index}>{message}</div>;
                    })}
                </Alert>
            ) : (
                <></>
            )}

            <GenericFields
                fields={newForm}
                fieldsetName="new_appliance"
                indexedValues={newAppliance.current}
                fieldsetLayout={FIELDSET_LAYOUT.COMPOUND}
                selectHandler={(name, value) => {
                    newAppliance.current[name] = value;
                }}
            />
        </section>
    );
};

export const Appliance = (props) => {
    const isSmallDevice = isDeviceSmall();
    const type = APPLIANCE_TYPES[props.field.options.applianceType];
    const {loader, dialog, buttonClickHandher} = useAppliance(
        type,
        props.field.name
    );
    const [dataSource, setDataSource] = useState({dataSourceParams: type});
    const {values, setFieldValue} = useFormikContext();
    const {productDataStore} = useProductContext();
    const heightFieldName = props.field.options['heightFieldName'];
    const heightFieldMoreInfo = props.field.options['heightFieldMoreInfo'];
    const advancedCheckboxFieldName =
        props.field.options['advancedCheckboxFieldName'];

    const formConfig =
        type == 1
            ? []
            : [
                  {
                      name: heightFieldName,
                      displayName: 'Opening Height',
                      type: 'size',
                      value: 0,
                      options: {
                          moreInfo: heightFieldMoreInfo,
                          enabled: `${advancedCheckboxFieldName} == 0`,
                      },
                  },
                  {
                      name: advancedCheckboxFieldName,
                      displayName: 'Advanced Selection',
                      type: 'boolean',
                      value: 0,
                  },
              ];

    useEffect(() => {
        if (!loader) {
            setDataSource({dataSourceParams: type});
        }
    }, [loader]);

    const applianceHandler = (name, value, options) => {
        const selectedOption = options.find((option) => option.id == value);

        if (selectedOption) {
            setFieldValue(heightFieldName, selectedOption.height);
        }
    };

    return (
        <Loader loader={loader}>
            <div style={{borderBottom: '1px solid', marginBottom: '15px'}}>
                <h2 style={{width: 'fit-content', padding: '5px 15px'}}>
                    {props.field.displayName}
                </h2>
            </div>

            <GenericFields
                fields={formConfig}
                fieldsetName="appliance"
                fieldsetLayout={FIELDSET_LAYOUT.COMPOUND}
            />

            {type == 1 || values[advancedCheckboxFieldName] ? (
                <>
                    <Form.Group as={Row}>
                        <Form.Label column xs={4}>
                            {`${props.field.displayName} Selection`}

                            <MoreInfo
                                scope={
                                    productDataStore.current &&
                                    productDataStore.current.cabinet &&
                                    productDataStore.current.cabinet.attributes
                                        ? productDataStore.current.cabinet
                                              .attributes
                                        : {}
                                }
                                info={props.field.options.moreInfo}
                            />
                        </Form.Label>
                        <Col xs="12" sm="auto" style={{width: 250}}>
                            {props.dropdown.render({
                                ...props,
                                ...dataSource,
                                ...{
                                    selectHandler: (name, value, options) => {
                                        applianceHandler(name, value, options);
                                        setFieldValue(name, value);
                                    },
                                    hasImage: 'image',
                                    extraHandler: applianceHandler,
                                },
                            })}
                        </Col>
                        <Col xs="12" sm="1">
                            <OverlayTrigger
                                placement={isSmallDevice ? 'top' : 'right'}
                                overlay="Add a new Appliance">
                                <Icon
                                    style={{
                                        width: '25px',
                                        cursor: 'pointer',
                                        marginTop: '6px',
                                        marginLeft: isSmallDevice
                                            ? '0'
                                            : '-10px',
                                    }}
                                    iconName="Button-Plus.svg"
                                    onClick={buttonClickHandher}
                                />
                            </OverlayTrigger>
                        </Col>
                    </Form.Group>
                </>
            ) : (
                <></>
            )}
            {dialog}
        </Loader>
    );
};
