import {useGetRoomProductsQuery} from 'components/customer/Product/store/productApi';
import {useJobContext} from 'contexts';
import {useEffect, useMemo, useState} from 'react';
import {PartialJob} from 'shared/types/PartialJob';
import {SidebarProduct} from 'shared/components/Header/entity/SidebarProduct';

export const useFetchSidebarProducts = () => {
    const [sidebarProducts, setSidebarProducts] = useState<SidebarProduct[]>(
        []
    );
    const {job, room} = useJobContext() as PartialJob;
    const {roomIndex, roomId} = useMemo(() => {
        if (job && job.rooms && room && room.id) {
            return {
                roomIndex: job.rooms.findIndex(
                    (jobRoom) => jobRoom.id == room.id
                ),
                roomId: room.id,
            };
        }

        return {
            roomId: undefined,
            roomIndex: undefined,
        };
    }, [room, job]);

    const {
        data: products,
        isLoading,
        isFetching,
        isError,
    } = useGetRoomProductsQuery(
        {roomId},
        {
            skip: typeof roomId === 'undefined',
        }
    );

    useEffect(() => {
        if (roomIndex >= 0 && products && Array.isArray(products)) {
            setSidebarProducts(
                products.map((product, index) => {
                    const sidebarProduct: SidebarProduct = {
                        id: product.id,
                        isQFP: product.in_qfp_summary == 1,
                        type: product.type,
                        quantity: product.quantity,
                        note: product.note,
                        dimensions: product.dimensions,
                        currencyType: '$',
                        productNumber: `${roomIndex + 1}-${index + 1}`,
                        typeName: product.type_name,
                        cost: product.cost,
                    };

                    return sidebarProduct;
                })
            );
        } else if (!roomIndex) {
            // no room index means it's deleted
            setSidebarProducts([]);
        }
    }, [products, roomIndex]);

    return {
        products: sidebarProducts,
        isFetching,
        isLoading,
        isError,
    };
};
