import {useNotificationContext} from 'contexts';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Image} from 'react-bootstrap';
import {useConfirmationDialog} from 'shared';
import {
    genericMessageHandler,
    useComponentMountedHelper,
    usePromise,
} from 'shared/helpers';
import {debounce, isEqual} from 'lodash';
import {useFormikContext} from 'formik';
import {isDeviceSmall} from 'shared/helpers/DeviceSize';
import SelectField from 'shared/components/SelectField';

interface DropdownDecoratorProps {
    field?: {
        dispayName: string,
    };
    name: string;
    value: string;
    selectHandler?: (name: string, value: string, options: unknown[]) => void;
    setFieldValue?: (name: string, value: string) => void;
    dataSource: unknown[];
    dataSourceParams?: boolean;
    enabled?: boolean;
    hasImage?: boolean;
    hasDescription?: boolean;
    isQFP?: boolean;
    isInvalid: boolean;
    placeholder?: string;
    fieldSetIndex?: number;
    fieldSetGroupName?: string;
    inSpecsTab?: boolean;
    [x: string]: unknown;
}

export const DropdownDecorator = ({
    field,
    name,
    value,
    setFieldValue,
    selectHandler,
    dataSource,
    dataSourceParams = false,
    enabled = true,
    hasImage = false,
    hasDescription = false,
    isQFP = false,
    isInvalid,
    placeholder = '',
    fieldSetIndex = -1,
    fieldSetGroupName = '',
    inSpecsTab,
}: DropdownDecoratorProps) => {
    const {notify} = useNotificationContext();
    const {isMounted} = useComponentMountedHelper();
    const {dialog, showDialog, hideDialog} = useConfirmationDialog();
    const formik = useFormikContext();
    const {values} = formik ? formik : {};

    const [options, setOptions] = useState(
        Array.isArray(dataSource) ? dataSource : []
    );
    const [disabled, setDisabled] = useState(!enabled);
    const [image, setImage] = useState();
    const [showImageInPopup, setShowImageInPopup] = useState(false);
    const [description, setDescription] = useState('');
    const [showDescription, setShowDescription] = useState(true);
    const [loader, setLoader] = useState(false);

    const dataSourceParamsRef = useRef({});
    const initialLoad = useRef(true);
    const isSmallDevice = isDeviceSmall();

    const fixedWidth = inSpecsTab && isSmallDevice;

    const updateValue = useCallback(
        (name, value, options) => {
            if (typeof value !== 'undefined') {
                if (selectHandler) {
                    selectHandler(name, value, options);
                } else {
                    setFieldValue(name, value);
                }

                if (formik) {
                    formik.setFieldTouched(name, true);
                }
            }
        },
        [values, options]
    );

    const imageAndDescription = (options) => {
        const selectedOption =
            options &&
            options.length &&
            options.find((option) => option.id == value);

        if (selectedOption) {
            hasImage && setImage(selectedOption[hasImage]);
            isMounted.current &&
                hasDescription &&
                setDescription(selectedOption[hasDescription]);
            isMounted.current && hasDescription && setShowDescription(true);

            return selectedOption;
        }
    };

    const fetchData = (dataSourceParams_, dataSource_) => {
        if (dataSource_) {
            if (typeof dataSource_ === 'function') {
                if (!isEqual(dataSourceParamsRef.current, dataSourceParams_)) {
                    dataSourceParamsRef.current = dataSourceParams_;
                    isMounted.current && setLoader(true);
                    isMounted.current && setDisabled(true);
                    return usePromise(
                        ([data]) => {
                            if (data) {
                                if (data.length) {
                                    isMounted.current && setOptions(data);
                                    isMounted.current &&
                                        imageAndDescription(data);
                                } else {
                                    if (isMounted.current) {
                                        setOptions([]);

                                        if (isQFP) {
                                            if (!initialLoad.current) {
                                                updateValue(name, undefined);
                                            }
                                        } else {
                                            updateValue(name, -1);
                                        }
                                    }
                                }
                            }

                            initialLoad.current = false;
                            isMounted.current && enabled && setDisabled(false);
                            isMounted.current && setLoader(false);
                        },
                        [dataSource_(dataSourceParams_)],
                        (errors) => {
                            isMounted.current && setDisabled(false);
                            isMounted.current && setLoader(false);
                            genericMessageHandler(notify, errors);
                        }
                    );
                }
            } else {
                if (dataSource_.length) {
                    isMounted.current && setOptions(dataSource_);
                }
            }
        }
    };

    const debouncedEffect = useRef(
        debounce(fetchData, 250, {trailing: true, maxWait: 1000})
    ).current;

    useEffect(() => {
        if (isQFP) {
            fetchData(dataSourceParams, dataSource);
        } else {
            debouncedEffect(dataSourceParams, dataSource);
        }
    }, [dataSourceParams, dataSource]);

    useEffect(() => {
        setDisabled(!enabled);
    }, [enabled]);

    useEffect(() => {
        if (dataSourceParams) {
            usePromise(
                ([data]) => {
                    data && data.length && imageAndDescription(data);
                },
                [dataSource(dataSourceParams)]
            );
        }
    }, [values.fingerPull_styles]);

    useEffect(() => {
        showImageInPopup &&
            showDialog({
                title: 'Image Preview',
                message: `<img src="/${image}" id="imagepreview"></img>`,
                hideYesButton: true,
                hideNoButton: true,
                buttons: [
                    {
                        name: 'Ok',
                        show: true,
                        action: () => {
                            setShowImageInPopup(false);
                            hideDialog();
                        },
                    },
                ],
            });
    }, [showImageInPopup]);

    const dropdown = useMemo(() => {
        const selectOptions = options?.map((option) => ({
            ...option,
            label: option.name,
            value: String(option.id),
        }));
        const fullWidth = [
            'shelf_type',
            'shelf_style',
            'simple_shelf_type',
            'hinge_style',
            'upper_shelf_type',
            'upper_shelf_style',
            'appliance_id',
            'lower_shelf_type',
            'lower_shelf_style',
            'middle_shelf_type',
            'middle_shelf_style',
            'drawer_face_type',
            'microwave_id',
        ].includes(name);

        let fieldName = name;
        const grouped =
            fieldSetIndex !== null && fieldSetIndex >= 0 && !!fieldSetGroupName;
        if (grouped) {
            fieldName = `${fieldSetGroupName}[${fieldSetIndex}.${name}]`;
        }
        return (
            <SelectField
                id={fieldName}
                name={fieldName}
                options={selectOptions}
                isDisabled={disabled}
                customOnChange={!grouped ? updateValue : undefined}
                defaultFirstValue
                width={fullWidth || isQFP ? undefined : '150px'}
                isLoading={loader}
                placeholder={
                    placeholder == ''
                        ? field.displayName !== ''
                            ? `Loading ${field.displayName}...`
                            : 'Loading data...'
                        : placeholder
                }
                invalid={isInvalid}
                isQFP={isQFP}
            />
        );
    }, [disabled, options, name, value, loader, field, isInvalid, values]);

    return (
        <>
            {dialog}
            {dropdown}

            {(hasImage || hasDescription) &&
            (image || (description && description.trim() != '')) ? (
                <section
                    style={{
                        padding: '10px 0',
                        width: fixedWidth ? 250 : 'auto',
                        marginLeft: fixedWidth ? -100 : 0,
                    }}>
                    {hasImage && image ? (
                        <section
                            className="dropdown-image"
                            style={{
                                width: '150px',
                                marginRight: '10px',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                setShowImageInPopup(true);
                            }}>
                            <Image
                                src={`/${image}`}
                                thumbnail
                                style={{textAlign: 'center'}}
                            />
                        </section>
                    ) : null}

                    {hasDescription &&
                    description &&
                    description.trim() != '' &&
                    showDescription ? (
                        <section
                            className="dropdown-description"
                            style={{
                                flex: 1,
                                fontSize: '.75em',
                                textAlign: 'justify',
                            }}>
                            {description}
                        </section>
                    ) : null}
                </section>
            ) : null}
        </>
    );
};
