import React from 'react';
import {useAppContext} from 'contexts';
import {useBenchtopTableConfig} from 'hooks';
import {JobCBCTable} from 'shared/components/Table/JobCBCTable';

export const SidebarRightBenchtop = () => {
    const {tableConfig, dialog} = useBenchtopTableConfig();
    const {userProfile} = useAppContext();

    return (
        <>
            {userProfile.isBTDAvailable && tableConfig ? (
                <JobCBCTable
                    options={tableConfig}
                    className="word-break-cbc-table"
                />
            ) : null}
            {dialog}
        </>
    );
};
