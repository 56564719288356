import React from 'react';
import {useProductTableConfig} from 'shared/components/Header/helper/useProductRightSideBar';
import {useAppContext} from 'contexts';
import {JobCBCTable} from 'shared/components/Table/JobCBCTable';

export const SidebarRightProducts = () => {
    const {tableConfig, dialog, isFetching} = useProductTableConfig();
    const {userProfile} = useAppContext();
    return (
        <>
            {(tableConfig && userProfile.isAddProductAvailable) ||
            (tableConfig && tableConfig.data?.length) ||
            isFetching ? (
                <JobCBCTable
                    options={tableConfig}
                    className="word-break-cbc-table"
                />
            ) : null}
            {dialog}
        </>
    );
};
