import React from 'react';
import {useGetBenchId} from 'components/customer/BTM/helper/useGetBenchId';
import {invalidateProductAndCost} from 'components/customer/Product/store/productApi';
import {useAppContext, useJobContext, useNotificationContext} from 'contexts';
import {Form} from 'react-bootstrap';
import {useLocation} from 'react-router-dom';
import {copyRoom, deleteRoom} from 'service';
import {useConfirmationDialog} from 'shared/components/ConfirmationDialog/ConfirmationDialog';
import {genericMessageHandler, numberFormat, urlPartsJob} from 'shared/helpers';
import {useAppDispatch} from 'store/customer';
import {invalidateBenches} from 'components/customer/BTM/store/btmApi';
import {useFetchSidebarRooms} from 'shared/components/Header/helper/useFetchSidebarRooms';
import {PartialRoom} from 'shared/types/PartialRoom';
import {OptionsType} from 'shared/types';
import {useNavigate} from 'shared/reload/helper/useNavigate';
import {PartialJob} from 'shared/types/PartialJob';

export const useRoomsSidebar = () => {
    const {benchId} = useGetBenchId();
    const navigate = useNavigate();
    const location = useLocation();
    const {notify} = useNotificationContext();
    const [jobId, roomId] = urlPartsJob();
    const {
        job,
        room,
        refresh,
        isPageDirty,
        setIsPageDirty,
        actionButtonsEnabled,
        expiredJobPrice,
        setRoom,
    } = useJobContext() as PartialJob;

    const deleteRoomMessage = `Are you sure you want to delete this Room?`;
    const {showDialog, dialog} = useConfirmationDialog();
    const dispatch = useAppDispatch();
    const {userProfile} = useAppContext();
    const {rooms} = useFetchSidebarRooms();

    const handleRoomChange = (selectedRoom: PartialRoom) => {
        const isRoomSelected = roomId && location.pathname.indexOf(roomId) > -1;

        if (isRoomSelected) {
            if (benchId == null) {
                navigate(
                    location.pathname
                        .split(roomId)
                        .join(String(selectedRoom.id)),
                    {
                        state: {room: selectedRoom},
                    }
                );
            } else {
                if (userProfile.isBenchtopAvailable) {
                    navigate(
                        `/v2/job/${jobId}/room/${selectedRoom.id}/benchtop-module`,
                        {
                            state: {room: selectedRoom},
                        }
                    );
                }
            }
        } else {
            // set room if no redirection is done.
            // Usually happns in dashboard page.

            const currentRoomSelected = job?.rooms?.find(
                (room) => room.id === selectedRoom.id
            );

            if (currentRoomSelected) {
                setRoom(currentRoomSelected);
            } else {
                setRoom(selectedRoom);
            }
        }
    };

    const deleteRoomHandler = (
        row: PartialRoom,
        event: React.MouseEvent<HTMLElement>
    ) => {
        const button = event.currentTarget.parentElement as HTMLButtonElement;
        showDialog({
            title: 'Delete Room',
            message: deleteRoomMessage,
            yes: async () => {
                // You can make this function async so that function code is much more clearer
                button.disabled = true;
                try {
                    const response = await deleteRoom(row.jobId, row.id);

                    if (response) {
                        genericMessageHandler(
                            notify,
                            {message: 'Room deleted'},
                            'success'
                        );
                    } else
                        genericMessageHandler(notify, {
                            message:
                                'Could not delete the room right now, Please try again later.',
                        });

                    refresh({reloadFromServer: true, setNewRoom: true});
                    setIsPageDirty([]);
                    dispatch(invalidateBenches());
                    dispatch(invalidateProductAndCost());
                    button.disabled = false;
                } catch (error) {
                    genericMessageHandler(notify, error);
                }
            },
        });
    };

    const copyRoomHandler = (
        row: PartialRoom,
        event: React.MouseEvent<HTMLElement>,
        icon: string,
        setIcon: (icon: string) => void
    ) => {
        const ogIcon = icon;
        const button = event.currentTarget.parentElement as HTMLButtonElement;
        const hasProduct =
            row.jobBenchtops.length > 0 || row.jobCabinets.length > 0;
        const copyMessage = hasProduct
            ? 'Would you like to also copy the current products of this room?'
            : `Are you sure you want to copy this room?`;

        showDialog({
            title: 'Copy Room',
            message: copyMessage,
            yes: async () => {
                button.disabled = true;
                setIcon('tail-spin.svg');
                try {
                    await copyRoom(row.jobId, row.id, false);
                    genericMessageHandler(
                        notify,
                        {message: 'Room successfully copied.'},
                        'success'
                    );
                    dispatch(invalidateProductAndCost());
                    refresh({});
                    setIsPageDirty([]);
                } catch (error) {
                    genericMessageHandler(notify, error);
                } finally {
                    setIcon(ogIcon);
                    button.disabled = false;
                }
            },
            no: async () => {
                button.disabled = true;
                if (hasProduct) {
                    setIcon('tail-spin.svg');
                    try {
                        await copyRoom(row.jobId, row.id, true);
                        genericMessageHandler(
                            notify,
                            {message: 'Room successfully copied.'},
                            'success'
                        );
                        dispatch(invalidateProductAndCost());

                        refresh({});
                        setIsPageDirty([]);
                    } catch (error) {
                        genericMessageHandler(notify, error);
                    } finally {
                        setIcon(ogIcon);
                        button.disabled = false;
                    }
                } else {
                    button.disabled = false;
                }
            },
            buttons: [
                {
                    name: 'Cancel',
                    variant: 'warning',
                    show: hasProduct,
                },
            ],
        });
    };

    const tableConfig: OptionsType<PartialRoom> = {
        fields: [
            {
                fieldName: 'name',
                title: 'Current Rooms',
                format: function RoomCheckBox(value, room_) {
                    return (
                        <div
                            className="cbc-radio"
                            onClick={() => {
                                if (room_.id != room.id) {
                                    handleRoomChange(room_);
                                }
                            }}>
                            <Form.Check
                                style={{cursor: 'pointer'}}
                                type="radio"
                                id={`selectedRoom_${room_.id}`}
                                name="selectedRoom"
                                value={room_.id}
                                checked={room_.id === room.id}
                                label={value}
                                onChange={() => handleRoomChange(room_)}
                            />
                        </div>
                    );
                },
            },
            {
                fieldName: 'name',
                title: 'Items',
                width: 80,
                format: function formatRoomName(value, room_) {
                    return (
                        <div onClick={() => handleRoomChange(room_)}>
                            {' '}
                            {room_.productCount}{' '}
                        </div>
                    );
                },
            },
            userProfile.show_pricing && {
                fieldName: 'name',
                title: 'Cost',
                width: 65,
                format: function formatCost(value, room_) {
                    return (
                        <div
                            style={
                                !actionButtonsEnabled || job.status == 5
                                    ? {textAlign: 'center'}
                                    : {}
                            }
                            onClick={() => handleRoomChange(room_)}>
                            {(job.currencyType ? job.currencyType : '$') +
                                numberFormat(
                                    (
                                        Math.round(
                                            (room_.cost + Number.EPSILON) * 100
                                        ) / 100
                                    ).toFixed(2)
                                )}{' '}
                        </div>
                    );
                },
            },
        ],
        data: rooms,
        hasCursor: true,
        noRecordsText: `No Rooms`,
    };

    if (actionButtonsEnabled && job.status != 5) {
        tableConfig.options = {
            title: '',
            width: 75,
            buttons: [
                {
                    iconName: 'Options-Edit.svg',
                    title: 'Edit Room',
                    action: (row) => {
                        navigate(`/v2/job/${jobId}/room/${row.id}`, {
                            state: {room: row},
                        });
                    },
                },
                {
                    iconName: 'Options-Copy.svg',
                    title: 'Copy Room',
                    action: copyRoomHandler,
                    confirmChanges: isPageDirty.length > 0,
                },
                {
                    iconName: 'Options-Delete.svg',
                    title: 'Delete Room',
                    action: deleteRoomHandler,
                    confirmChanges: isPageDirty.length > 0,
                },
            ],
        };
    }

    if (expiredJobPrice) {
        tableConfig.options = undefined;
    }

    return {
        tableConfig,
        dialog,
    };
};
