import React, {useCallback, useState} from 'react';
import {Placement} from 'react-bootstrap/esm/Overlay';
import {Overlay, Tooltip} from 'react-bootstrap';
import {uniqueId} from 'lodash';

export enum OverlayType {
    ToolTip,
    Popover,
}

type OverlayTriggerProps = {
    placement?: Placement;
    overlay: JSX.Element | string;
    children: JSX.Element;
    className?: string;
};

export const OverlayTrigger = ({
    placement = 'auto-start',
    overlay,
    children,
    className = '',
}: OverlayTriggerProps): JSX.Element => {
    const [show, setShow] = useState(false);

    const target = React.useRef<HTMLElement>(null);

    const onMouseEnter = useCallback(() => {
        setShow(true);
    }, []);

    const onMouseLeave = useCallback(() => {
        setShow(false);
    }, []);

    if (typeof overlay === 'undefined' || overlay == '') {
        return children;
    }

    return (
        <>
            {React.Children.map(children, (child) =>
                React.cloneElement(child, {
                    ref: target,
                    onMouseEnter,
                    onMouseLeave,
                })
            )}
            <Overlay
                flip={true}
                transition={false}
                target={target.current}
                show={show}
                placement={placement}>
                {overlay ? (
                    <Tooltip className={className} id={uniqueId('tooltip')}>
                        {overlay}
                    </Tooltip>
                ) : null}
            </Overlay>
        </>
    );
};
