import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import {
    useNotificationContext,
    useProductContext,
    useAppContext,
} from 'contexts';
import {OverlayTrigger} from 'shared';
import {validationErrorAppliesToField} from 'shared/validator';
import {mapErrors} from 'shared/helpers/mapErrors';

export const FormControl = ({
    name,
    value,
    selectHandler,
    type = 'text',
    hasFormik = true,
    isInvalid = false,
    message = '',
    disabled = false,
    isQFP = false,
    randomId,
    fieldIndex,
    fieldSetIndex,
    wholeNumber = false,
    preventMinInput = true,
    forceUpdateValueOnChange = false,
    autoFocus = false,
    ...otherOptions
}) => {
    const [localValue, setLocalValue] = useState(value);
    const formikContext = hasFormik ? useFormikContext() : false;
    const {messages} = useNotificationContext();
    const [tooltip, setTooltip] = useState(message);
    const productSpecificData = useProductContext();
    const {smallLayout} = useAppContext();
    const [_, {error}] = hasFormik
        ? useField({name, type, multiple: true})
        : [undefined, {error: undefined}];
    const [fieldIsInvalid, setFieldIsInvalid] = useState(isInvalid);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [fieldUpdating, setFieldUpdating] = useState(false);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

    const updateValue = (newValue) => {
        if (typeof selectHandler !== 'undefined' && selectHandler) {
            selectHandler(name, newValue);
        } else {
            formikContext && formikContext.setFieldValue(name, newValue);
        }
    };

    useEffect(() => {
        setTooltip(mapErrors(message));
    }, [message]);

    useEffect(() => {
        if (isQFP) {
            if (error) {
                setTooltip(error);
                setFieldIsInvalid(true);
            } else {
                if (fieldIndex >= 0 && fieldSetIndex >= 0 && isInvalid) {
                    // fieldIndex is row index. i.e. index of the each qfp product
                    // fieldSetIndex is still the index of the particular fieldset

                    const errorMessages = messages.errors;
                    const productErrors = errorMessages[Number(fieldIndex)];

                    if (productErrors && Array.isArray(productErrors)) {
                        const tooltips = productErrors
                            .filter((error) =>
                                validationErrorAppliesToField(
                                    error,
                                    name,
                                    fieldSetIndex
                                )
                            )
                            .map((error) => error.message);

                        if (tooltips.length > 0) {
                            setTooltip(mapErrors(tooltips));
                            setFieldIsInvalid(true);
                        }
                    }
                }
                setFieldIsInvalid(isInvalid);
            }
        }
    }, [error, isInvalid, messages]);

    useEffect(() => {
        if (!isQFP) {
            let errorMessages = messages.errors;
            let index = fieldSetIndex;

            if (
                productSpecificData &&
                productSpecificData.hasOwnProperty('fieldIndex') &&
                productSpecificData.fieldIndex > -1
            ) {
                index = productSpecificData.fieldIndex;
            }

            if (
                index >= 0 &&
                !Array.isArray(errorMessages) &&
                messages.errors.hasOwnProperty(index)
            ) {
                errorMessages = messages.errors[Number(index)];
            }

            if (index >= 0 && Array.isArray(messages.errors)) {
                errorMessages = messages.errors.filter((error) =>
                    error.fields.includes(`${name}[${index + 1}]`)
                );
            }

            if (
                isInvalid &&
                Array.isArray(errorMessages) &&
                errorMessages.length > 0
            ) {
                const toolTips = errorMessages
                    .filter((error) =>
                        validationErrorAppliesToField(error, name, index)
                    )
                    .map((error) => error.message);

                setTooltip(mapErrors(toolTips, smallLayout));
                setFieldIsInvalid(true);
            } else {
                setFieldIsInvalid(false);
            }
        }
    }, [error, messages, isInvalid, smallLayout]);

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    useEffect(() => {
        // this effect ensures field are sync before form submission
        if (value !== localValue) {
            setFieldUpdating(true);
        } else {
            setFieldUpdating(false);
        }
    }, [value, localValue]);

    useEffect(() => {
        // this effect will trigger form submission once field values are sync and form is submitted via enter key
        if (!fieldUpdating && formSubmitted) {
            formikContext && formikContext.submitForm();
            setFormSubmitted(false);
        }
    }, [fieldUpdating, formSubmitted]);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        const targetValue = isIOS ? parseFloat(inputValue) : inputValue;

        if (forceUpdateValueOnChange) {
            updateValue(targetValue);
            return;
        }

        if (wholeNumber && Math.floor(targetValue) != targetValue) {
            return;
        }

        if (
            preventMinInput &&
            otherOptions.min &&
            otherOptions.min > targetValue
        ) {
            return;
        }

        setLocalValue(targetValue);

        if (event.target !== document.activeElement) {
            updateValue(targetValue);
        }
    };

    const handleBlurCustom = (e) => {
        formikContext && formikContext.handleBlur(e);
        if (value !== localValue) {
            let updatedLocalValue = localValue;
            if (type === 'number' && typeof localValue === 'string') {
                if (localValue.length > 0) {
                    updatedLocalValue =
                        localValue.indexOf('.') >= 0
                            ? parseFloat(localValue)
                            : parseInt(localValue);
                }
            }

            updateValue(updatedLocalValue);
        }
    };

    const enterKeyPressed = (event) => {
        if (event.keyCode === 13) {
            if (
                otherOptions &&
                otherOptions.hasOwnProperty('as') &&
                otherOptions.as == 'textarea'
            ) {
                return;
            }
            handleBlurCustom(event);
            setFormSubmitted(true);
        }
    };

    const formControl = (
        <Form.Control
            {...otherOptions}
            autoFocus={autoFocus}
            disabled={disabled}
            type={type}
            name={name}
            value={localValue}
            onChange={handleChange}
            onKeyUp={enterKeyPressed}
            onBlur={handleBlurCustom}
            isInvalid={fieldIsInvalid}
            data-cy={`${name}${
                !isNaN(fieldSetIndex) ? `-${fieldSetIndex + 1}` : ''
            }`}
            inputMode={type == 'number' ? 'numeric' : undefined}
        />
    );

    if (fieldIsInvalid && typeof tooltip !== 'undefined' && tooltip != '') {
        return (
            <OverlayTrigger
                className="error-popover"
                as="popover"
                trigger={['focus', 'hover']}
                overlay={tooltip}
                placement={isQFP ? 'auto-start' : 'right-end'}>
                <div style={{flex: 1}}>{formControl}</div>
            </OverlayTrigger>
        );
    }

    return formControl;
};
