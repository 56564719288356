import {useCallback, useContext, useEffect} from 'react';
import {UNSAFE_NavigationContext as NavigationContext} from 'react-router-dom';

const useBlocker = (blocker: (any) => void, when = true): void => {
    const {navigator} = useContext(NavigationContext);

    useEffect(() => {
        if (!when) return;

        if (navigator && navigator.hasOwnProperty('block')) {
            const unblock = navigator.block((tx) => {
                const autoUnblockingTx = {
                    ...tx,
                    retry() {
                        // Automatically unblock the transition so it can play all the way
                        // through before retrying it. TODO: Figure out how to re-enable
                        // this block if the transition is cancelled for some reason.
                        unblock();
                        tx.retry();
                    },
                };

                blocker(autoUnblockingTx);
            });

            return unblock;
        }
    }, [navigator, blocker, when]);
};

export const usePrompt = (
    message: string | ((any, any) => void),
    when = true
): void => {
    const blocker = useCallback(
        (tx) => {
            if (typeof message === 'string') {
                if (window.confirm(message)) tx.retry();
            } else {
                message(tx, () => tx.retry());
            }
        },
        [message]
    );

    useBlocker(blocker, when);
};
