import React, {useCallback} from 'react';
import {DndProvider} from 'react-dnd';
import {isMobile} from 'react-device-detect';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import {DropTarget} from 'shared/components/DragAndDrop/DropTarget';
import {produce, Draft} from 'immer';

export interface GenericItem {
    id: number;
}

export interface DropProps<T> {
    isDnD?: boolean;
    hasBody?: boolean;
    findItem?: (id: number) => {item: T; index: number};
    moveItem?: (id: number, atIndex: number) => void;
}

interface DropTargetProps<T> {
    isTable?: boolean;
    draggables?: T[];
    setDraggables?: (items: T[]) => void;
}

export const withDropTarget = <T extends GenericItem, R>(
    Component: React.ComponentType<DropProps<T>>
) => {
    const DragNDropComponent = ({
        draggables,
        setDraggables,
        isTable,
        ...props
    }: R & DropTargetProps<T>) => {
        const findItem = useCallback(
            (id: number) => {
                const item = draggables.find((item) => item.id === id);
                return {item, index: draggables.indexOf(item)};
            },
            [draggables]
        );

        const moveItem = useCallback(
            (id: number, atIndex: number) => {
                const {item, index} = findItem(id);

                setDraggables(
                    produce(draggables, (draft) => {
                        draft.splice(index, 1);
                        draft.splice(atIndex, 0, item as Draft<T>);
                    })
                );
            },
            [findItem, draggables, setDraggables]
        );

        return (
            <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
                <DropTarget isTable={isTable}>
                    <Component
                        {...props}
                        hasBody={isTable}
                        isDnD={true}
                        findItem={findItem}
                        moveItem={moveItem}
                    />
                </DropTarget>
            </DndProvider>
        );
    };

    return DragNDropComponent;
};
