import React, {useCallback} from 'react';
import {Modal} from 'react-bootstrap';
import {CBCButton} from 'shared/helpers';
import styled from 'styled-components';

const StyledFooter = styled(Modal.Footer)`
    background: rgb(var(--primary_colour));
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding: 10px;
    justify-content: space-around;
`;

// FIXME: Use CBCButton instead of inline component when we have
// a proper cypress test.
const Button = styled((props) => <CBCButton {...props} />)`
    border-radius: 20px !important;
    padding: 5px 10px 5px 5px !important;
    width: fit-content !important;
    font-size: 0.95em !important;
    &:hover {
        border: 1px solid white;
    }
`;

const OkButton = styled.div`
    color: white;
    font-style: italic;
    text-decoration: underline;
    font-size: 0.9em;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
`;

export enum CloseEventType {
    CANCEL = 'cancel',
    APPLY = 'apply',
    OK = 'ok',
}

interface FooterInterface {
    yesButtonText?: string;
    noButtonText?: string;
    okButtonText?: string;
    onClose?: (event: CloseEventType) => void;
    className?: string;
    displayFlex?: boolean;
    okIcon?: string;
}

const Footer = ({
    yesButtonText,
    noButtonText,
    okButtonText,
    onClose,
    className,
    displayFlex,
    okIcon,
}: FooterInterface) => {
    const cancel = useCallback(() => {
        onClose(CloseEventType.CANCEL);
    }, [onClose]);

    const apply = useCallback(() => {
        onClose(CloseEventType.APPLY);
    }, [onClose]);

    const ok = useCallback(() => {
        onClose(CloseEventType.OK);
    }, [onClose]);

    return (
        <StyledFooter className={className}>
            {noButtonText ? (
                <Button
                    onClick={cancel}
                    iconName="Button-Cancel.svg"
                    className="job-button button-light"
                    style={{color: '#8A8080'}}>
                    {noButtonText}
                </Button>
            ) : null}
            <div style={displayFlex ? {display: 'flex'} : {}}>
                {displayFlex ? (
                    <>
                        {okButtonText !== '' ? (
                            <Button
                                onClick={ok}
                                type="button"
                                iconName={okIcon ? okIcon : 'Button-Tick.svg'}
                                className="job-button button-blue">
                                {okButtonText}
                            </Button>
                        ) : null}

                        {yesButtonText !== '' ? (
                            <Button
                                style={{marginLeft: '30px'}}
                                onClick={apply}
                                type="button"
                                iconName="Button-Tick.svg"
                                className="job-button button-blue">
                                {yesButtonText}
                            </Button>
                        ) : null}
                    </>
                ) : (
                    <>
                        <Button
                            onClick={apply}
                            type="button"
                            iconName="Button-Tick.svg"
                            className="job-button button-blue">
                            {yesButtonText}
                        </Button>
                        <OkButton onClick={ok}>{okButtonText}</OkButton>
                    </>
                )}
            </div>
        </StyledFooter>
    );
};

export default Footer;
