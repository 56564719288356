import {useEffect, useCallback, DependencyList} from 'react';
import {useAppSelector} from 'store/customer';
import {shallowEqual} from 'react-redux';
import {
    getUI,
    UISliceInterface,
    SidebarStatus,
    SidebarBehaviour,
    defaultRightSidebarBehavior,
    defaultLeftSidebarBehavior,
} from 'store/customer/UISlice';
import useUI from 'shared/useUI';

export const useLeftSidebarBehaviour = ({
    behavior,
    setStatusOn = true,
    deps,
}: {
    behavior: SidebarBehaviour;
    setStatusOn?: boolean;
    deps?: DependencyList;
}) => {
    const {setLeftSidebarBehaviour} = useUI();

    useEffect(() => {
        if (setStatusOn) {
            setLeftSidebarBehaviour(behavior);
        }

        return () => {
            setLeftSidebarBehaviour(defaultLeftSidebarBehavior);
        };
    }, [setStatusOn, ...(deps ?? [])]);
};

export const useRightSidebarBehaviour = ({
    behavior,
    setStatusOn = true,
    deps,
    defaultStatusOnCleanUp,
}: {
    behavior: SidebarBehaviour;
    setStatusOn?: boolean;
    deps?: DependencyList;
    defaultStatusOnCleanUp?: SidebarStatus;
}) => {
    const {setRightSidebarBehaviour, overrideRightSidebar} = useUI();

    useEffect(() => {
        if (setStatusOn) {
            setRightSidebarBehaviour(behavior);
        }

        return () => {
            setRightSidebarBehaviour(defaultRightSidebarBehavior);
            if (!!defaultStatusOnCleanUp) {
                // this will be the default state if provided
                overrideRightSidebar(defaultStatusOnCleanUp);
            }
        };
    }, [setStatusOn, ...(deps ?? [])]);
};

export const useHiddenSidebar = ({
    hideOn = true,
    deps,
}: {
    hideOn?: boolean | (() => boolean);
    deps?: DependencyList;
}) => {
    const {
        overrideLeftSidebarBehaviour,
        overrideRightSidebarBehaviour,
        setIsCartToggleVisible,
    } = useUI();

    useEffect(() => {
        if (
            (typeof hideOn === 'boolean' && hideOn) ||
            (typeof hideOn === 'function' && hideOn())
        ) {
            overrideLeftSidebarBehaviour(SidebarBehaviour.INVISIBLE);
            overrideRightSidebarBehaviour(SidebarBehaviour.INVISIBLE);
            setIsCartToggleVisible(false);
        }
    }, [...(deps ?? [])]);
};

export const useRightSidebar = () => {
    const {
        setRightSidebar,
        alternateCartContent,
        setShowMainCartContent,
        showMainCartContent,
        rightSidebarBehaviour,
        isSmallScreenLayout,
        rightSidebar,
        overrideRightSidebar,
        setRightSmallScreenBehaviour,
        setRightSmallScreenStatus,
    } = useUI();
    const ui = useAppSelector<UISliceInterface>(getUI, shallowEqual);

    const openSmallScreenRightSidebar = () => {
        setRightSidebar(SidebarStatus.OPEN);
        setRightSmallScreenBehaviour(SidebarBehaviour.TOGGLE);
        setRightSmallScreenStatus(SidebarStatus.OPEN);
    };

    const toggle = useCallback(() => {
        if (ui.rightSidebar.behaviour == SidebarBehaviour.TOGGLE) {
            setRightSidebar(
                ui.rightSidebar.status == SidebarStatus.OPEN
                    ? SidebarStatus.CLOSED
                    : SidebarStatus.OPEN
            );
        }

        if (isSmallScreenLayout) {
            if (
                ui.rightSidebar.smallScreenBehaviour ==
                SidebarBehaviour.AUTOHIDE
            ) {
                setRightSidebar(
                    ui.rightSidebar.status == SidebarStatus.OPEN
                        ? SidebarStatus.CLOSED
                        : SidebarStatus.OPEN
                );

                setRightSmallScreenBehaviour(SidebarBehaviour.TOGGLE);
                setRightSmallScreenStatus(SidebarStatus.OPEN);
            }

            if (
                ui.rightSidebar.smallScreenBehaviour ==
                    SidebarBehaviour.TOGGLE &&
                ui.rightSidebar.smallScreenStatus == SidebarStatus.CLOSED
            ) {
                setRightSmallScreenBehaviour(SidebarBehaviour.AUTOHIDE);
            }
        }
    }, [ui.rightSidebar.status, ui.rightSidebar.behaviour, setRightSidebar]);

    const sidebarCartButtonHandler = useCallback(
        (_: React.MouseEvent<HTMLElement, MouseEvent>, toggle = false) => {
            if (alternateCartContent && !toggle) {
                setShowMainCartContent(!showMainCartContent);
            } else {
                let status = SidebarStatus.OPEN;

                if (rightSidebarBehaviour == SidebarBehaviour.TOGGLE) {
                    status = SidebarStatus.CLOSED;
                }

                setRightSidebar(status);
            }

            if (isSmallScreenLayout) {
                if (
                    ui.rightSidebar.smallScreenBehaviour ==
                    SidebarBehaviour.TOGGLE
                ) {
                    setRightSmallScreenBehaviour(SidebarBehaviour.AUTOHIDE);
                    setRightSmallScreenStatus(SidebarStatus.CLOSED);
                }
            }
        },
        [
            rightSidebarBehaviour,
            alternateCartContent,
            showMainCartContent,
            isSmallScreenLayout,
            ui.rightSidebar.smallScreenBehaviour,
            setRightSidebar,
        ]
    );

    const onMouseEnter = useCallback(() => {
        if (!isSmallScreenLayout) return;
        if (rightSidebarBehaviour == SidebarBehaviour.TOGGLE) return;
        if (rightSidebar == SidebarStatus.CLOSED) {
            overrideRightSidebar(SidebarStatus.OPEN);
        }
    }, [rightSidebar, rightSidebarBehaviour, isSmallScreenLayout]);

    const onMouseLeave = useCallback(() => {
        if (!isSmallScreenLayout) return;
        if (rightSidebarBehaviour == SidebarBehaviour.TOGGLE) return;
        if (rightSidebar == SidebarStatus.OPEN) {
            overrideRightSidebar(SidebarStatus.CLOSED);
        }
    }, [rightSidebar, rightSidebarBehaviour, isSmallScreenLayout]);

    return {
        toggle,
        sidebarCartButtonHandler,
        onMouseEnter,
        onMouseLeave,
        openSmallScreenRightSidebar,
    };
};

export const useLeftSidebar = () => {
    const {
        setLeftSidebar,
        isSmallScreenLayout,
        leftSidebar,
        overrideLeftSidebar,
        leftSidebarBehaviour,
    } = useUI();
    const ui = useAppSelector<UISliceInterface>(getUI, shallowEqual);

    const toggle = useCallback(() => {
        setLeftSidebar(
            ui.leftSidebar.status == SidebarStatus.CLOSED
                ? SidebarStatus.OPEN
                : SidebarStatus.CLOSED
        );
    }, [ui.leftSidebar.status, setLeftSidebar]);

    const onMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            if (!isSmallScreenLayout) return;

            if (
                (e.target as Element).classList.contains('left-sidebar-toggle')
            ) {
                return;
            }

            if (leftSidebar == SidebarStatus.CLOSED) {
                overrideLeftSidebar(SidebarStatus.OPEN);
            }
        },
        [leftSidebar, leftSidebarBehaviour, isSmallScreenLayout]
    );

    const onMouseLeave = useCallback(() => {
        if (!isSmallScreenLayout) return;
        if (leftSidebar == SidebarStatus.OPEN) {
            overrideLeftSidebar(SidebarStatus.CLOSED);
        }
    }, [leftSidebar, leftSidebarBehaviour, isSmallScreenLayout]);

    return {
        toggle,
        onMouseEnter,
        onMouseLeave,
    };
};
