import React from 'react';
import {useSundries} from 'hooks';
import {useAppContext} from 'contexts';
import {JobCBCTable} from '../Table/JobCBCTable';

export const SidebarRightSundries = () => {
    const {tableConfig, dialog} = useSundries();
    const {userProfile} = useAppContext();

    return (
        <>
            {(userProfile.isSundryAvailable && tableConfig) ||
            (tableConfig && tableConfig.data.length) ? (
                <JobCBCTable
                    options={tableConfig}
                    className="word-break-cbc-table"
                />
            ) : (
                <></>
            )}
            {dialog}
        </>
    );
};
